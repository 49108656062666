<script setup lang="ts">
import { ref, computed } from 'vue';
import { useEventBus } from '@vueuse/core';
import { Alert } from 'flowbite-vue';
import { Vue3Lottie } from 'vue3-lottie';
import { useRuntimeConfig } from '#app';
import { useUtilityStore } from '@utilities/stores';
import DropzoneInitialState from '@utilities/components/Modals/UploadUtilityOrderModal/DropzoneInitialState.vue';
import type { FormUploadPayload } from '@utilities/types/requests';
import * as checkAnimation from 'assets/animations/check.json';
import { useHelpers } from '~/composables/useHelpers';
import {
  globalKey,
  UPLOAD_UTILITY_ORDER_OPEN,
  UPLOAD_UTILITY_ORDER_CLOSE,
} from '~/utils/events';
import { TENANT_TYPES } from '@utilities/constants';
import { PropertyContext } from '#components';
import { useMainStore } from '~/stores/mainStore';
import UploadTenantTypeSelector
  from '@utilities/components/Modals/UploadUtilityOrderModal/UploadTenantTypeSelector.vue';

const utilityStore = useUtilityStore();
const mainStore = useMainStore();
const { formatBytes } = useHelpers();
const runtimeConfig = useRuntimeConfig();
const bucket = runtimeConfig.public.vapor.awsBucketPrivate;
const bus = useEventBus(globalKey);
const isOpen = ref(false);
const loading = ref(false);
const uploadedForms = ref(new Map());
const property = ref({});
const error = ref('');
const success = ref(false);

bus.on((event, payload) => {
  if (event === UPLOAD_UTILITY_ORDER_OPEN) {
    isOpen.value = true;

    if (payload && 'property' in payload) {
      property.value = payload.property;
    } else {
      property.value = {};
    }
  }

  if (event === UPLOAD_UTILITY_ORDER_CLOSE) {
    isOpen.value = false;
  }
});

interface FormTenantType {
  uuid: string;
  type: string | null;
}

const formTenantTypes = ref<FormTenantType[]>([]);

const options = {
  autoProcessQueue: true, // This causes the queue to process using the overwritten uploadFiles function
  parallelUploads: 1,
  previewsContainer: false,
  uploadMultiple: true,
  acceptedFiles: '.pdf',
};

const hasProperty = computed(() => Object.keys(property.value).length > 0);

const isTenantTypesValid = computed(() => {
  const uploadedFormsUUIDs = Array.from(uploadedForms.value.keys());
  const formTenantTypesUUIDs = formTenantTypes.value.map(item => item.uuid);
  return uploadedFormsUUIDs.every(uuid => formTenantTypesUUIDs.includes(uuid));
});

async function reset() {
  error.value = '';
  if (success.value) {
    await utilityStore.refresh();
    mainStore.totalAccounts += uploadedForms.value.size;
  }

  uploadedForms.value.clear();
  formTenantTypes.value = [];
  success.value = false;
}

function removeItem(uuid: string) {
  uploadedForms.value.delete(uuid);
  formTenantTypes.value = formTenantTypes.value.filter(item => item.uuid !== uuid);
}

function markAllAs(type: string) {
  uploadedForms.value.forEach((form) => {
    uploadedForms.value.set(form.upload.uuid, {
      ...form,
      tenantType: type,
    });
  });
}

async function confirm() {
  try {
    loading.value = true;

    const payload: FormUploadPayload[] = [];

    uploadedForms.value.forEach((form) => {
      const item = {
        uuid: form.aws.uuid,
        property_id: null,
        bucket: form.aws.bucket,
        extension: form.aws.extension,
        key: form.aws.key,
        name: form.upload.filename,
        tenant_type: null,
      };

      const formTenantType = formTenantTypes.value.find(ft => ft.uuid === form.upload.uuid);
      if (formTenantType) {
        item.tenant_type = formTenantType.type;
      }

      if (property.value) {
        item.property_id = property.value.id;
      }

      payload.push(item);
    });

    await utilityStore.uploadForms({ forms: payload });

    loading.value = false;
    success.value = true;
  } catch (error) {
    console.error(error);
    loading.value = false;
  }
}

function onError(file, message) {
  error.value = message;
  uploadedForms.value.set(file.upload.uuid, {
    ...file,
    pending: false,
    failed: true,
  });
}

function onFileAdded(file) {
  if(isTenantTypesValid.value) error.value = '';
  uploadedForms.value.set(file.upload.uuid, {
    ...file,
    pending: true,
    failed: false,
  });
}

function onSuccess(file) {
  uploadedForms.value.set(file.upload.uuid, {
    ...file,
    pending: false,
    failed: false,
  });
}

function onTenantTypeSelected(payload: { uuid: string; type: string | null }) {
  const index = formTenantTypes.value.findIndex(item => item.uuid === payload.uuid);

  if (index !== -1) {
    formTenantTypes.value[index].type = payload.type;
  } else {
    formTenantTypes.value = [...formTenantTypes.value, payload];
  }
}

async function onClose() {
  await reset();
  isOpen.value = false;
}
</script>
<template>
  <UhModal min-height="auto" :open="isOpen" :escapable="false" @close="onClose">
    <template v-if="!success" #header>
      <span class="text-primary-grey"
        >Upload Utility Management Service Orders</span
      >
    </template>

    <template #body>
      <VaporDropzone
        v-if="!success"
        id="utility-form-upload"
        :bucket="bucket"
        :options="options"
        @error="onError"
        @added-file="onFileAdded"
        @success="onSuccess"
      >
        <Alert v-if="error" type="danger" class="mb-6">
          {{ error }}
        </Alert>
        <Alert v-if="!isTenantTypesValid" type="danger" class="mb-6">
          Please select a tenant type for all uploaded forms.
        </Alert>
        <PropertyContext v-if="hasProperty" :property="property" class="mb-6" />

        <ul v-if="uploadedForms.size" class="mb-4">
          <li
            v-for="form in uploadedForms.values()"
            :key="form.upload.uuid"
            class="flex items-center justify-between border-b py-2 gap-4"
          >
            <div class="flex flex-1 items-center w-0">
              <div class="flex flex-1 min-w-0 gap-2">
                <span class="text-sm truncate">{{ form.upload.filename }}</span>
                <span class="flex-shrink-0 text-xs text-gray-500">{{
                  formatBytes(form.upload.total)
                }}</span>
              </div>
            </div>

            <UhSpinner v-if="form.pending" />

            <div v-else class="flex items-center gap-4">
              <UploadTenantTypeSelector
                :uuid="form.upload.uuid"
                :tenant-type="form.tenantType"
                @tenant-type-selected="onTenantTypeSelected"
              />

              <UhButton
                color="alternative"
                size="sm"
                @click="removeItem(form.upload.uuid)"
              >
                <FontAwesomeIcon :icon="['far', 'trash']" />
              </UhButton>
            </div>
          </li>
        </ul>

        <DropzoneInitialState v-if="uploadedForms.size === 0" />
        <UhButton v-else color="alternative" class="w-full" for="dropzone-file"
          ><FontAwesomeIcon :icon="['far', 'plus']" class="mr-2" />Add
          more</UhButton
        >
      </VaporDropzone>

      <div v-if="uploadedForms.size > 1 && !success" class="flex items-center justify-center space-x-4 border-t mt-4 pt-6">
        <UhButton color="alternative" @click="markAllAs(TENANT_TYPES.STUDENT)">
          Mark all as student
        </UhButton>
        <UhButton color="alternative" @click="markAllAs(TENANT_TYPES.NON_STUDENT)">
          Mark all as non-student
        </UhButton>
        <UhButton color="alternative" @click="markAllAs(TENANT_TYPES.MIXED)">
          Mark all as mixed
        </UhButton>
      </div>

      <div v-if="success" class="mb-4 text-center">
        <Vue3Lottie
          class="mx-auto"
          :width="60"
          :height="60"
          :animation-data="checkAnimation.default"
          :loop="1"
        />
        <h2 class="mb-4 text-xl font-semibold">
          We've got your {{ uploadedForms.size > 2 ? 'orders!' : 'order!' }}
        </h2>
        <p class="mb-8 px-6">
          Your uploaded Utility Service Order Forms will be processed by our the
          UniHomes team and once ready, they'll appear in your account.
        </p>
        <UhButton class="w-32" @click="onClose">Done</UhButton>
      </div>
    </template>

    <template v-if="!success" #footer>
      <div class="flex w-full justify-between">
        <UhButton color="alternative" :disabled="loading" @click="onClose">
          Cancel
        </UhButton>
        <UhButton
          data-cy="confirm-upload"
          :loading="loading"
          :disabled="uploadedForms.size === 0 || !isTenantTypesValid"
          @click="confirm"
        >
          Upload
        </UhButton>
      </div>
    </template>
  </UhModal>
</template>
