<script setup lang="ts">
import { computed } from 'vue';
import type { User } from '~/types/user';

const runtimeConfig = useRuntimeConfig();
const apiBaseUrl = runtimeConfig.public.apiBaseUrl;
const leaveImpersonationHref = computed(
  () => `${apiBaseUrl}/letting-agent/impersonate-leave`
);

const { user }: { user: User } = useAuth();
const name = computed(() => `${user.first_name} ${user.last_name}`);
</script>

<template>
  <div
    v-if="user?.impersonated"
    class="align-center left-0 right-0 z-50 flex h-8 w-full justify-center bg-red-600 text-center"
    data-cy="impersonating-banner"
  >
    <span class="my-auto block text-xs text-white">
      <FontAwesomeIcon :icon="['fas', 'triangle-exclamation']" /> You are
      impersonating
      <span class="hidden font-bold sm:inline-block"> {{ name }}.</span>
      <a
        class="ml-2 font-bold underline"
        data-cy="impersonating-banner-stop-impersonating"
        :href="leaveImpersonationHref"
        >Stop impersonating</a
      >
    </span>
  </div>
</template>
