<script setup lang="ts">
const { toggleDrawer } = useLayout();
</script>

<template>
  <button
    aria-controls="sidebar-menu"
    type="button"
    class="inline-flex items-center rounded-lg text-sm text-white hover:text-gray-100 focus:outline-none xl:hidden"
    data-testid="sidebar-menu"
    @click="toggleDrawer"
  >
    <span class="sr-only">Open sidebar</span>
    <svg
      class="h-6 w-6"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        fill-rule="evenodd"
        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
      ></path>
    </svg>
  </button>
</template>
