<script setup lang="ts">
import { ref, watch } from 'vue';
import { TENANT_TYPES } from '@utilities/constants';

type UploadTenantTypeSelectorProps = {
  uuid: string,
  tenantType: string | null
}

const props = withDefaults(
  defineProps<UploadTenantTypeSelectorProps>(),
  {
    tenantType: null,
  }
);

const emit = defineEmits(['tenantTypeSelected']);

const tenantType = ref<string | null>(props.tenantType);

watch(() => props.tenantType, (newTenantType) => {
  tenantType.value = newTenantType;
});

watch(() => tenantType.value, (newTenantType) => {
  emit('tenantTypeSelected', { uuid: props.uuid, type: newTenantType });
});

const selectOption = (option: string) => {
  tenantType.value = option;
};

const isSelected = (type: string) => tenantType.value === type;
</script>

<template>
  <div>
    <div class="flex items-center gap-4">
      <label
        v-for="type in Object.values(TENANT_TYPES)"
        :key="type"
        :class="[
          'h-10 flex items-center justify-center border border-gray-300 rounded-lg px-2 py-2 text-sm cursor-pointer hover:border-gray-600',
          isSelected(type) ? 'border-gray-600 ring-1 ring-gray-900' : ''
        ]"
        @click.stop="selectOption(type)"
      >
        <input
          v-model="tenantType"
          type="radio"
          name="tenant_type"
          :value="type"
          class="hidden"
        />
        {{ type }}
      </label>
    </div>
  </div>
</template>
