<script setup lang="ts">
import type { NotificationData } from '@notifications/types';
import { useNotificationsStore } from '@notifications/stores';
import UhTooltip from '~/components/general/uhTooltip/UhTooltip.vue';
const dayjs = useDayjs();
const notificationsStore = useNotificationsStore();

type NotificationCardProps = {
  notification: NotificationData;
};

const props = defineProps<NotificationCardProps>();

</script>

<template>
  <div
    v-if="props.notification.data?.message"
    class="relative cursor-pointer border-b px-6 py-4 hover:bg-gray-50"
  >
    <div class="flex items-center justify-between" @click.stop="notificationsStore.markAsRead(notification.id)">
      <p class="pr-6 text-sm">
        {{ props.notification.data.message }}
      </p>
      <UhTooltip hover placement="left">
        <button
          v-if="!notification.isRead"
          type="button"
          class="relative z-[2] h-2 w-2 rounded-full bg-red-500"
          @click.stop="notificationsStore.markAsRead(notification.id)"
        ></button>

        <template #content>Mark as read</template>
      </UhTooltip>
    </div>
    <p class="mt-1 text-xs text-gray-500">
      {{ dayjs(notification.createdAt).fromNow() }}
    </p>
  </div>
</template>
